/**
 * CraftApi plugin
 * Please use in coordination with the Element API plugin
 * https://github.com/craftcms/element-api
 */

import axios from 'axios';
import i18n from '../i18n';

const ELEMENT_TYPE = 'pages';

class CraftApi {
    constructor() {
        if (!process.env.VUE_APP_SITE_BASE_URL) {
            // eslint-disable-next-line no-console
            console.error('Error: Please set VUE_APP_SITE_BASE_URL in .env');
        }

        this.baseUrl = '/api';
    }


  /**
   *
   * Gets everything for debug purposes
   * Enabled only in development
   *
   * @return object
   *
   */
  async getMainNavigation() {
    let response;

    const LOCALE = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

    await axios
      .get(`${process.env.VUE_APP_SITE_BASE_URL + LOCALE + this.baseUrl}/mainNav.json`)
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        response = {
          code: 500,
          message: 'An error occured',
          page: null,
        };
      });

    return response;
  }

    async getFooterNavigation() {
        let response;

        const LOCALE = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + LOCALE + this.baseUrl}/footer.json`)
            .then((res) => {
                response = res;
            })
            .catch((err) => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                };
            });

        return response;
    }

    async getContactInformation() {
        let response;

        const LOCALE = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + LOCALE + this.baseUrl}/contact.json`)
            .then((res) => {
                response = res;
            })
            .catch((err) => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                };
            });

        return response;
    }


    async sendContactFormData(formData, csrfToken) {

        try {
            const response = axios.post('/', formData, {
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Content-type': 'application/json',
                },
            })
            console.log(response);
            return response
        } catch (error) {
            console.warn(error)
        }
    }


    /**
     *
     * Gets any entry by slug, and defaults to pages
     *
     * @param slug
     * @param type
     *
     * @return object
     *
     */
    async getContentBySlug(slug, type = ELEMENT_TYPE) {
        let response;

        const LOCALE = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + LOCALE + this.baseUrl}/${type}/${slug}.json`)
            .then((res) => {
                response = res;
            })
            .catch((err) => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                };
            });

        return response;
    }


    /**
     *
     * Gets related entries by type
     *
     * @param type
     *
     * @return object
     *
     */
    async getRelatedContent(type = ELEMENT_TYPE) {
        let response;

        const LOCALE = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + LOCALE + this.baseUrl}/${type}-related.json`)
            .then((res) => {
                response = res;
            })
            .catch((err) => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                };
            });

        return response;
    }


    /**
     *
     * Gets ALL entries by type for landing pages
     *
     * @param type
     *
     * @return object
     *
     */
    async getContentByType(type = ELEMENT_TYPE) {
        let response;

        const LOCALE = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + LOCALE + this.baseUrl}/${type}-all.json`)
            .then((res) => {
                response = res;
            })
            .catch((err) => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                };
            });

        return response;
    }

    /**
     *
     * Gets everything for debug purposes
     * Enabled only in development
     *
     * @return object
     *
     */
    async getAllEntries() {
        let response;

        const LOCALE = i18n.locale === 'fr' ? '' : '/' + i18n.locale;

        if (process.env.NODE_ENV === 'production') {
            return {
                code: 403,
                message: 'Forbidden',
            };
        }

        await axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL + LOCALE + this.baseUrl}/debug.json`)
            .then((res) => {
                response = res;
            })
            .catch((err) => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                };
            });

        return response;
    }
}

export default {
    install(Vue, options) {
        Vue.prototype.$craft = new CraftApi();
    },
};
