import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';

Vue.use(VueRouter);

const routes = [

    // Accueil
    {
        path: '/',
        component: () => import(/* webpackChunkName: "accueil" */ '../views/Home.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: { lang: 'en' },
    },


    // Page - Listing des spectacles
    {
        path: '/programmation',
        component: () => import(/* webpackChunkName: "programmation" */ '../views/Events.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/program/',
        component: () => import(/* webpackChunkName: "program" */ '../views/Events.vue'),
        meta: { lang: 'en' },
    },
    // Page - Listing Place Orientalys
    {
        path: '/place-orientalys',
        component: () => import(/* webpackChunkName: "place-orientalys" */ '../views/Creations.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/orientalys-square',
        component: () => import(/* webpackChunkName: "orientalys-square" */ '../views/Creations.vue'),
        meta: { lang: 'en' },
    },


    // Page - Listing des Éditions - FR
    {
        path: '/editions-precedentes',
        component: () => import(/* webpackChunkName: "editions-precedentes" */ '../views/Editions.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/previous-editions',
        component: () => import(/* webpackChunkName: "previous-editions" */ '../views/Editions.vue'),
        meta: { lang: 'en' },
    },



    // Page - Listing des Pays d'Orient
    {
        path: '/pays-orient',
        component: () => import(/* webpackChunkName: "pays-orient" */ '../views/Arabitudes.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/lands-orient',
        component: () => import(/* webpackChunkName: "lands-orient" */ '../views/Arabitudes.vue'),
        meta: { lang: 'en' },
    },

    // Page - Contact Us
    {
        path: '/contactez-nous',
        component: () => import(/* webpackChunkName: "contactez-nous" */ '../views/Contact.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/contact-us',
        component: () => import(/* webpackChunkName: "contact-us" */ '../views/Contact.vue'),
        meta: { lang: 'en' },
    },

    // Page - Partners
    {
        path: '/partenaires',
        component: () => import(/* webpackChunkName: "partenaires" */ '../views/Partners.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/partners',
        component: () => import(/* webpackChunkName: "partners" */ '../views/Partners.vue'),
        meta: { lang: 'en' },
    },

    // Page - Qui sommes-nous?
    {
        path: '/qui-sommes-nous',
        component: () => import(/* webpackChunkName: "qui-sommes-nous" */ '../views/About.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/who-we-are',
        component: () => import(/* webpackChunkName: "who-we-are" */ '../views/About.vue'),
        meta: { lang: 'en' },
    },

    // Page - Festival Team
    {
        path: '/equipe-festival',
        component: () => import(/* webpackChunkName: "equipe-festival" */ '../views/Team.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/festival-team',
        component: () => import(/* webpackChunkName: "festival-team" */ '../views/Team.vue'),
        meta: { lang: 'en' },
    },

    // Page - Medias
    {
        path: '/medias',
        component: () => import(/* webpackChunkName: "medias" */ '../views/Medias.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/medias',
        component: () => import(/* webpackChunkName: "medias" */ '../views/Medias.vue'),
        meta: { lang: 'en' },
    },

    // Page - Legal notice
    {
        path: '/mentions-legales',
        component: () => import(/* webpackChunkName: "mentions-legales" */ '../views/LegalNotice.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/legal-notice',
        component: () => import(/* webpackChunkName: "legal-notice" */ '../views/LegalNotice.vue'),
        meta: { lang: 'en' },
    },


    // Page - Sales conditions
    {
        path: '/conditions-de-vente',
        component: () => import(/* webpackChunkName: "conditions-de-vente" */ '../views/SalesConditions.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/sales-conditions',
        component: () => import(/* webpackChunkName: "sales-conditions" */ '../views/SalesConditions.vue'),
        meta: { lang: 'en' },
    },

    // Page - Règlement Vieux-Port
    {
        path: '/reglement-vieux-port',
        component: () => import(/* webpackChunkName: "reglement-vieux-port" */ '../views/RulesOldPort.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/rules-old-port',
        component: () => import(/* webpackChunkName: "rules-old-port" */ '../views/RulesOldPort.vue'),
        meta: { lang: 'en' },
    },

    // Single - Evenement / spectacle
    {
        path: '/spectacle/:slug',
        component: () => import(/* webpackChunkName: "evenement" */ '../views/Event.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/show/:slug',
        component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue'),
        meta: { lang: 'en' },
    },


    // Single - Place Orientalys
    {
        path: '/place-orientalys/:slug',
        component: () => import(/* webpackChunkName: "place-orientalys" */ '../views/Event.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/orientalys-square/:slug',
        component: () => import(/* webpackChunkName: "orientalys-square" */ '../views/Event.vue'),
        meta: { lang: 'en' },
    },


    // Single - Pays d'Orient
    {
        path: '/pays-orient/:slug',
        component: () => import(/* webpackChunkName: "pays-orient" */ '../views/Arabitude.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/lands-orient/:slug',
        component: () => import(/* webpackChunkName: "lands-orient" */ '../views/Arabitude.vue'),
        meta: { lang: 'en' },
    },

    // Single - Édition
    {
        path: '/editions-precedentes/:slug',
        component: () => import(/* webpackChunkName: "editions-precedentes" */ '../views/Edition.vue'),
        meta: { lang: 'fr' },
    },
    {
        path: '/en/previous-editions/:slug',
        component: () => import(/* webpackChunkName: "previous-editions" */ '../views/Edition.vue'),
        meta: { lang: 'en' },
    },

    {
        path: '*',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'fr' },
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,

    scrollBehavior(to, from, savedPosition) {
        console.log('Scroll behavior')
        if (to.hash) {
            console.log('to hash!', to.hash)
            return { selector: to.hash }
        } else {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 })
                }, 200)
            })
        }
    }
});



router.beforeEach(async (to, from, next) => {
    store.commit('SET_DATA_READY', false);
    const { lang } = to.meta;
    document.documentElement.lang = lang;
    i18n.locale = lang;

    setTimeout(() => {
        next();
    }, 250); // 250 is time of page loader fade-in
})


export default router;
