<template>
    <div>

        <!-- DESKTOP -->
        <section class="main_menu menu-desktop">
            <div class="container">
                <div class="inner">
                    <nav v-if="navItems">

                        <ul class="top_menu">
                            <!-- Array starts with an empty 0 index (must investigate) so we slice it off -->
                            <li class="menu_parent_item"
                                :class="navItem.links.length > 1 ? 'has-children' : ''"
                                v-for="(navItem, i) in navItems.slice(1)" :key="i">

                                <!-- FIRST LEVEL -->
                                <template v-if="navItem.links.length == 1">
                                    <router-link :to="$t('nav.home.uri')"
                                                 class="logo-link"
                                                 :title="$t('nav.home.title')"
                                                 v-if="navItem.links[0].title === 'ACCUEIL' || navItem.links[0].title === 'HOME'">
                                        <img class="logo" src="@/assets/images/orientalys-logo.svg">
                                    </router-link>

                                    <router-link :to="getURL(navItem.links[0].url)"
                                                 :title="navItem.links[0].title" v-else>
                                        <span>{{navItem.links[0].title}}</span>
                                    </router-link>
                                </template>

                                <!-- SECOND LEVEL -->
                                <template v-if="navItem.links.length > 1">
                                    <!-- Item at index 0 is the parent -->

                                    <span class="parent_item">{{navItem.links[0].title}}
                                            <img class="arrow" src="@/assets/images/arrow-menu.svg">
                                        </span>


                                    <ul class="sub_menu">
                                        <!-- Remove (slice) the first item in the list since it is the "parent" -->
                                        <li class="menu_child_item"
                                            v-for="(subItem, s) in navItem.links.slice(1)"
                                            :key="s">
                                            <router-link :to="getURL(subItem.url)"
                                                         :title="subItem.title">
                                                <span>{{subItem.title}}</span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </template>
                            </li>
                            <li class="menu_parent_item language-switcher_wrapper">
                                <a class="language-switcher" @click="changeLocale(otherLocale)">
                                    <span>{{ otherLocale }}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>


        <!-- TOUCH DEVICES -->
        <section class="main_menu menu-touch">
            <div class="outside">
                <div class="container ">
                    <div class="inner">
                        <router-link :to="$t('nav.home.uri')" :title="$t('nav.home.title')">
                            <img class="logo" src="@/assets/images/orientalys-logo.svg">
                        </router-link>

                        <div class="burger" :class="fullscreenMenuToggled ? 'open' : ''"
                             @click="toggleFullscreenMenu()">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <div class="language-switcher" @click="changeLocale(otherLocale)">
                            {{ otherLocale }}
                        </div>

                    </div>
                </div>
            </div>

            <transition name="fade">
                <div class="inside" v-if="fullscreenMenuToggled">

                    <div class="container">
                        <div class="inner">
                            <router-link :to="$t('nav.home.uri')" :title="$t('nav.home.title')"
                                         @click.native="toggleFullscreenMenu()">
                                <img class="logo" src="@/assets/images/orientalys-logo.svg">
                            </router-link>

                            <div class="burger" :class="fullscreenMenuToggled ? 'open' : ''"
                                 @click="toggleFullscreenMenu()">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <div class="language-switcher" @click="changeLocale(otherLocale)">
                                {{ otherLocale }}
                            </div>

                        </div>
                    </div>


                    <div class="content">
                        <nav v-if="navItems">
                            <ul>
                                <!-- Array starts with an empty 0 index (must investigate) so we slice it off -->
                                <li class="menu_parent_item"
                                    :class="navItem.links.length > 1 ? 'has-children ' + navItem.title.replace(' ', '_').toLowerCase() : ' ' + navItem.title.replace(' ', '_').toLowerCase()"
                                    v-for="(navItem, i) in navItems.slice(1)" :key="i">

                                    <!-- FIRST LEVEL -->
                                    <template v-if="navItem.links.length == 1">
                                        <router-link :to="$t('nav.home.uri')"
                                                     class="logo-link"
                                                     :title="$t('nav.home.title')"
                                                     v-if="navItem.links[0].title === 'ACCUEIL' || navItem.links[0].title === 'HOME'">
                                            <img class="logo" src="@/assets/images/orientalys-logo.svg">
                                        </router-link>

                                        <router-link :to="getURL(navItem.links[0].url)"
                                                     :title="navItem.links[0].title"
                                                     @click.native="toggleFullscreenMenu()"
                                                     v-else>
                                            <span>{{navItem.links[0].title}}</span>
                                        </router-link>
                                    </template>

                                    <!-- SECOND LEVEL -->
                                    <template v-if="navItem.links.length > 1">
                                        <!-- Item at index 0 is the parent -->

                                        <span class="parent_item" :ref="`menu${i}`"
                                              @click="toggleSubmenu(i)"
                                              :class="activeSubMenuIndex === i && activeSubMenu === true ? 'open' : ''">
                                            {{navItem.links[0].title}}
                                            <span class="toggle_submenu expand">+</span>
                                            <span class="toggle_submenu retract">-</span>
                                        </span>

                                        <ul class="sub_menu" :ref="`menu${i}`"
                                            :class="activeSubMenuIndex === i && activeSubMenu === true ? 'open' : ''">
                                            <!-- Remove (slice) the first item in the list since it is the "parent" -->
                                            <li class="menu_child_item"
                                                v-for="(subItem, s) in navItem.links.slice(1)"
                                                :key="s">
                                                <router-link :to="getURL(subItem.url)"
                                                             :title="subItem.title"
                                                             @click.native="toggleFullscreenMenu()">
                                                    <span>{{subItem.title}}</span>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </template>

                                </li>
                            </ul>
                        </nav>

                        <div class="extra_pages" v-if="extraPages">
                            <div class="container">
                                <div class="inner">
                                    <ul>
                                        <li v-for="(extraPage, i) in extraPages[0].links" :key="i">

                                            <router-link :to="getURL(extraPage.url)"
                                                         :title="extraPage.title"
                                                         @click.native="toggleFullscreenMenu()">
                                                <span>{{extraPage.title}}</span>


                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="social_links" v-if="socialLinks">
                            <div class="container">
                                <div class="inner">
                                    <ul>
                                        <li v-for="(socialLink, i) in socialLinks" :key="i">
                                            <a :href="socialLink.url" target="_blank"><span
                                                :class="`icon-`+socialLink.label | cleanString"></span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

        </section>


    </div>
</template>

<script>
    const html = document.getElementsByTagName('html')[0];

    export default {
        name: "MainMenu",
        components: {},
        data() {
            return {
                fullscreenMenuToggled: false,
                activeSubMenuIndex: null,
                previousActiveSubMenuIndex: null,
                activeSubMenu: false
            };
        },
        props: {
            navItems: {
                type: Array,
                default: () => [],
            },
            socialLinks: {
                type: Array,
                default: () => [],
            },
            extraPages: {
                type: Array,
                default: () => [],
            },
        },
        created() {

        },

        computed: {
            otherLocale() {
                return this.$i18n.locale == 'fr' ? 'en' : 'fr';
            },
            otherLocalesUrls() {
                return this.$store.state.otherLocalesUrls;
            },
        },

        methods: {

            /* To test once English is activated.
            *  Craft removes the leading / before a page slug. This creates
            *  wrong paths when already in a section: If you're in "events", then "/events/" will prefix every item menu.
            *  This makes sure (for now) that a leading / is added in french.
            *  */
            getURL(url) {
                if (this.$i18n.locale === 'fr') {
                    return `/${url}`;
                }

                return `/en/${url}`;
            },
            toggleFullscreenMenu() {
                this.fullscreenMenuToggled = !this.fullscreenMenuToggled;

                if (this.fullscreenMenuToggled) {
                    html.classList.add('noscroll');
                } else {
                    html.classList.remove('noscroll');
                }
            },


            toggleSubmenu(index) {

                if (this.previousActiveSubMenuIndex === index) {
                    this.activeSubMenu = false;
                } else {
                    this.activeSubMenu = true;
                }

                this.activeSubMenuIndex = index;
                this.previousActiveSubMenuIndex = this.activeSubMenuIndex;
            },


            changeLocale(newLocale) {
                if (this.$store.state.otherLocalesUrls) {

                    this.fullscreenMenuToggled = false;

                    const url = this.otherLocalesUrls[newLocale];
                    window.location.href = url || '/';
                }
            },

        },

        filters: {
            cleanString: function (value) {
                if (!value) return '';

                return value.trim().toLowerCase();
            }
        }
    }
</script>

<style scoped type="scss">
   
</style>
