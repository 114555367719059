import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Craft from './plugins/CraftApi';
import VueMeta from 'vue-meta'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueForceNextTick from 'vue-force-next-tick'
import browserDetect from "vue-browser-detect-plugin";
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import VueMq from 'vue-mq';

Vue.config.productionTip = false;
Vue.use(VueFilterDateFormat);
Vue.use(Craft);
Vue.use(VueForceNextTick);
Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    md: 768,
    lg: 1210,
  }
});
Vue.use(browserDetect);

gsap.registerPlugin(ScrollTrigger);


Vue.prototype.$csrfToken = window.Craft;

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
    debounceWait: 500,
});

new Vue({
  router,
  store,
  i18n,
  Craft,
  render: (h) => h(App),
}).$mount('#app');
