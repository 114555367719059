<template>
    <section class="prefooter appear" v-if="content">

        <img class="mandala big" src="@/assets/images/mandala-newsletter.png" data-appear="a4"/>

        <div class="intro">
            <span class="big" data-appear="a1">{{$t('prefooter.title')}}</span>
            <span data-appear="a2">{{$t('prefooter.text')}}</span>
        </div>
        <div class="container">
            <div class="inner">
                <ul>
                    <li v-for="(partner, p) in filteredPartners" :key="p">
                        <div class="partner">
                            <a :href="partner.partner_link" target="_blank" v-if="partner.partner_link !== ''">
                                <img :src="partner.partner_logo"  data-appear="a3" />
                            </a>
                            <img :src="partner.partner_logo" v-else/>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>

    export default {
        name: "PreFooter",
        components: {},
        computed: {
            filteredPartners() {
                return  this.content.filter(partner => partner.partner_category['value'] === 'major_partners');
            },
        },
        data() {
            return {
                content: null,
            }
        },
        created() {
            // Fetch the page's specific content
            this.$craft.getContentBySlug(this.$t('nav.partners.slug'))
                .then(res => {
                    this.content = res.data.partners;
                })
        },

        mounted() {

        }
    }
</script>

<style scoped>

</style>
